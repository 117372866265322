import { BIRDI_REGISTRATION_FLOW_PLAN } from 'enums/plans';
import { FormikProps, FormikValues } from 'formik';
import { graphql, navigate } from 'gatsby';
import { RegistrationMappers } from 'mappers-to-service/registration.mappers';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';
import Icon from 'ui-kit-v2/icon/icon';
import PromoCodeModal from 'ui-kit-v2/promo-code-modal/promo-code-modal';
import { Text } from 'ui-kit-v2/text/text';

import WorkflowNavigationSection from 'display-components/workflow-navigation/workflow-navigation';

import BirdiModalErrorContent from 'components/birdi-modal/birdi-modal-error-content';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';
import { PaymentForm } from 'components/payment-form';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { SelectPlanState } from 'state/membership-registration/membership-registration.reducer';
import {
    membershipRegistrationGetPlanDetailsRoutine,
    membershipRegistrationPromoCodeRoutine,
    membershipRegistrationUninsuredPaymetricCredentialsRoutine,
    membershipRegistrationUninsuredTokenizedCardNumberRoutine
} from 'state/membership-registration/membership-registration.routines';
import { membershipRegistrationSelectPlanListSelector } from 'state/membership-registration/membership-registration.selectors';
import { closeModalComponent } from 'state/modal/modal.reducer';
import { registrationCreateWebAccountRoutine } from 'state/registration/registration.routines';
import { registrationFormValuesSelector } from 'state/registration/registration.selector';
import { CreateWebAccountRequest } from 'state/registration/registration.types';

import { BREAKPOINTS } from 'const/breakpoints';

import { formatPrice } from 'schema/price.schema';

import { submitFormik } from 'util/form.helper';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackError, TrackEvent } from 'util/google_optimize/optimize_helper';
import { TRACKING_EVENTS } from 'util/registration.util';
import { CustomError } from 'util/service.helper';

import useCustomerIo from 'hooks/useCustomerIo';
import useRegistration from 'hooks/useRegistration';
import useWindowDimensions from 'hooks/useWindowDimensions';

import './payment-method-page.styles.scss';

const PaymentMethodPage = ({ data }: { data: GatsbyTypes.PaymentMethodPageDataQuery }) => {
    useRegistration();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();

    const [selectedPlan, setSelectedPlan] = useState<SelectPlanState>();
    const [promoCode, setPromoCode] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const promoSectionRef = useRef<HTMLDivElement>(null);
    const formValues = useSelector(registrationFormValuesSelector);
    const selectPlanValues = useSelector(membershipRegistrationSelectPlanListSelector);
    const appliedDiscount = selectedPlan?.membershipPlanAttributes[0].discountMonthly ?? 0;
    const firstMonthPayment = selectedPlan?.membershipPlanAttributes[0].pricingMonthly ?? 0;
    const totalValue = firstMonthPayment - appliedDiscount;
    const { trackEventCustomerIo, trackPageCustomerIo } = useCustomerIo();
    const planAlias = BIRDI_REGISTRATION_FLOW_PLAN.MEMBERSHIP;

    const formikRef = useRef<FormikProps<FormikValues> | null>(null);

    const asideContent = {
        title: t('membership.careStartHere.sideContent.title'),
        copyText: t('membership.careStartHere.sideContent.copy'),
        bottomImage: data?.asideBottomImage
    };

    const handleNavigation = () => {
        navigate(-1);
    };

    const handleFormik = async () => {
        if (formikRef.current) {
            submitFormik(formikRef.current);
        }
    };

    const handleSubmitForm = async (values: FormikValues) => {
        setIsSubmitting(true);
        const allFormsValues = getAllFormsValues(values);

        try {
            const credentials = (await getPaymetricCredentials()) as any;
            const cardData = prepareCardData(credentials, allFormsValues.cardNumber);
            await sendCardDataToPaymetric(credentials.serviceUrl, cardData);
            const pciToken = await handleTokenizedCardNumber(credentials.accessToken);
            const createWebAccountRequest = createWebAccount(allFormsValues, pciToken);
            await finalizeRegistration(createWebAccountRequest);
            trackSuccessfulRegistration(allFormsValues.typePlan, selectedPlan);
            navigate('/get-started-v2/success');
        } catch (error) {
            handleFailure(error as CustomError);
        } finally {
            setIsSubmitting(false);
        }
    };

    const getAllFormsValues = (values: FormikValues) => ({
        ...formValues,
        ...values,
        typePlan: 'Monthly',
        promoCode: promoCode,
        isAuthorizePayment: true,
        membershipPlanId: 1
    });

    const createWebAccount = (allFormsValues: any, pciToken: string): CreateWebAccountRequest => {
        return RegistrationMappers.createWebAccount(allFormsValues, pciToken);
    };

    const getPaymetricCredentials = async () => {
        return new Promise((resolve, reject) => {
            dispatch(
                membershipRegistrationUninsuredPaymetricCredentialsRoutine.trigger({
                    onSuccess: (credentials: any) => resolve(credentials),
                    onFailure: (error: any) => reject(error)
                })
            );
        });
    };

    const prepareCardData = (credentials: any, cardNumber: string) => {
        const cardData = window.$XIPlugin.createJSRequestPacket(credentials.merchantId, credentials.accessToken);
        cardData.addField(window.$XIPlugin.createField('SecureCardNumber', true, cardNumber));
        return cardData;
    };

    const sendCardDataToPaymetric = async (serviceUrl: string, cardData: any) => {
        return new Promise((resolve, reject) => {
            window.$XIPlugin.ajax({
                url: serviceUrl + 'Ajax',
                global: false,
                type: 'POST',
                data: cardData,
                success: resolve,
                error: reject
            });
        });
    };

    const handleTokenizedCardNumber = async (accessToken: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            dispatch(
                membershipRegistrationUninsuredTokenizedCardNumberRoutine.trigger({
                    formValues: { AccessToken: accessToken },
                    onSuccess: (cardInfo: { pciToken: { Body: string } }) => {
                        if (cardInfo) {
                            resolve(cardInfo.pciToken.Body as string);
                        } else {
                            reject(new Error('Card information is not available.'));
                        }
                    },
                    onFailure: () => {
                        reject();
                    }
                })
            );
        });
    };

    const finalizeRegistration = async (createWebAccountRequest: CreateWebAccountRequest) => {
        return new Promise((resolve, reject) => {
            dispatch(
                registrationCreateWebAccountRoutine.trigger({
                    ...createWebAccountRequest,
                    onSuccess: resolve,
                    onFailure: (error: CustomError) => {
                        TrackError(
                            'membershipRegistrationUninsuredRegisterRoutine',
                            TRACKING_EVENTS.FAILED_REGISTRATION,
                            error.message
                        );
                        reject(error);
                    }
                })
            );
        });
    };

    const trackSuccessfulRegistration = (typePlan: string, selectedPlan: any) => {
        TrackEvent(TRACKING_EVENTS.SUCCESSFUL_REGISTRATION, JSON.stringify(selectedPlan));
        trackEventCustomerIo({
            label: 'plan_purchased',
            value: {
                plan_name: selectedPlan?.membershipPlanDescription,
                billing_cycle: typePlan
            },
            plan: planAlias
        });
    };

    const trackTermsAndConditionsClick = (path: string) => {
        trackPageCustomerIo({ path, plan: planAlias });
    };

    const handleFailure = (error: CustomError) => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                onClose: () => {
                    dispatch(closeModal({}));
                },
                headerContent: <BirdiModalHeaderDanger headerText={'Error'} icon="alert" />,
                bodyContent: (
                    <BirdiModalErrorContent
                        description={error.message || t('membership.paymentMethod.errors.default')}
                        ctaText={t('profileSetup.buttons.gotIt')}
                        onClose={() => {
                            dispatch(closeModal({}));
                        }}
                    />
                ),
                ctas: []
            })
        );
    };

    const submitPromCode = (value: string) => {
        TrackEvent('promocode_clicked', TRACKING_EVENTS.PROMOCODE_CLICKED);
        if (!value) return;

        setPromoCode(value);
        dispatch(
            membershipRegistrationPromoCodeRoutine.trigger({
                promoCode: value,
                onFailure: () => {
                    setPromoCode('');
                    dispatch(membershipRegistrationGetPlanDetailsRoutine.trigger());
                },
                onSuccess: () => {
                    dispatch(closeModalComponent());
                    //Track the used promo code.
                    trackEventCustomerIo({
                        label: 'promo_code',
                        value: {
                            user: formValues.email,
                            code_value: value
                        },
                        plan: planAlias
                    });
                }
            })
        );
    };

    const handleRemoveDiscount = () => {
        setPromoCode('');
        dispatch(membershipRegistrationGetPlanDetailsRoutine.trigger());
    };

    useEffect(() => {
        dispatch(membershipRegistrationGetPlanDetailsRoutine.trigger());
        dispatch(
            membershipRegistrationUninsuredPaymetricCredentialsRoutine.trigger({
                onSuccess: (credentials: any) => {
                    const script = document.createElement('script');
                    script.src = credentials.scriptUrl;
                    script.async = true;
                    document.body.appendChild(script);
                }
            })
        );
    }, [dispatch]);

    useEffect(() => {
        // DRX-4146: we're currently hard-coding the selected plan to be Birdi Care monthly
        setSelectedPlan(selectPlanValues?.filter((plan) => plan.membershipPlanId === 1)?.[0]);
    }, [selectPlanValues]);

    useEffect(() => {
        promoSectionRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }, [promoCode, appliedDiscount]);

    return (
        <WorkflowLayout metaData={{ nodeTitle: 'Payment Method' }} isUnauthenticatedFlow>
            <LayoutWrapperComponent
                title={t('membership.paymentMethod.title')}
                headerAlign="left"
                workflowNavigation={
                    <WorkflowNavigationSection
                        limit={3}
                        usage={2}
                        customColor="picton-blue"
                        handleClick={handleNavigation}
                    />
                }
                workflowLogo={width >= BREAKPOINTS.lg ? data?.workflowLogo : undefined}
                asideContent={asideContent}
            >
                <div className="payment-method-page">
                    <div className="payment-method-page__plan-info">
                        <Text
                            size={16}
                            lineHeight={24}
                            weight={500}
                            color="dusk"
                            className="payment-method-page__plan-info__title"
                        >
                            {t('membership.paymentMethod.planInfo.title')}
                        </Text>
                        <ul className="payment-method-page__plan-info__list">
                            <li>
                                <Icon viewBox="-2 -4 24 24" icon="small-success" />
                                <Text size={14} lineHeight={21} weight={500} color="dusk">
                                    {t('membership.paymentMethod.planInfo.item1')}
                                </Text>
                            </li>
                            <li>
                                <Icon viewBox="-2 -4 24 24" icon="small-success" />
                                <Text size={14} lineHeight={21} weight={500} color="dusk">
                                    {t('membership.paymentMethod.planInfo.item2')}
                                </Text>
                            </li>
                            <li>
                                <Icon viewBox="-2 -4 24 24" icon="small-success" />
                                <Text size={14} lineHeight={21} weight={500} color="dusk">
                                    {t('membership.paymentMethod.planInfo.item3')}
                                </Text>
                            </li>
                        </ul>

                        <span className="payment-method-page__divider" />

                        <div className="payment-method-page__plan-info__values">
                            <div>
                                <Text lineHeight={21} letterSpacing={1} weight={500} color="dusk">
                                    {t('membership.paymentMethod.details.total')}
                                </Text>
                                <Text lineHeight={21} letterSpacing={1} weight={500} color="dusk">
                                    {formatPrice(firstMonthPayment)}
                                </Text>
                            </div>
                            <div>
                                <Text lineHeight={21} letterSpacing={1} weight={500} color="dusk">
                                    {t('membership.paymentMethod.details.fee')}
                                </Text>
                                <Text
                                    className="payment-method-page__plan-info__values--strikethrough"
                                    color="silver"
                                    size={14}
                                >
                                    {formatPrice(selectedPlan?.membershipPlanAttributes[0].joinFeeAmount ?? 0)}
                                </Text>
                                <Text color="dusk">$0.00</Text>
                            </div>

                            {promoCode && appliedDiscount > 0 && (
                                <div className="payment-method-page__plan-info__promo-code" ref={promoSectionRef}>
                                    <Text color="dusk">Promo: {promoCode}</Text>
                                    <Text color="dusk">-{formatPrice(appliedDiscount)}</Text>
                                    <ButtonComponent
                                        type="button"
                                        variant="link"
                                        size="sm"
                                        color="primary"
                                        label="Remove"
                                        onClick={handleRemoveDiscount}
                                    />
                                </div>
                            )}

                            <div className="payment-method-page__plan-info__payment">
                                <Text color="dusk">{t('membership.paymentMethod.details.payment')}</Text>
                                <Text weight={800} className="payment-method-page__plan-info__values--highlight">
                                    {formatPrice(totalValue)}
                                </Text>
                            </div>
                        </div>
                    </div>

                    <form className="payment-method-page__form">
                        <PaymentForm
                            ref={formikRef}
                            onSubmit={handleSubmitForm}
                            inputVariant={width > BREAKPOINTS.md ? 'alt' : 'default'}
                        />

                        <PromoCodeModal onSubmit={submitPromCode} />

                        <ButtonComponent
                            label={t('membership.paymentMethod.form.labels.getBirdiCareBtn')}
                            type="button"
                            onClick={handleFormik}
                            color="primary"
                            className="payment-method-page__form__submit-btn"
                            isLoading={isSubmitting}
                        />

                        <div className="payment-method-page__terms">
                            <Text color="slate">
                                <Trans
                                    i18nKey="membership.paymentMethod.terms.submitTerm1"
                                    values={{
                                        membershipTermsUrl: '/website-terms-of-use'
                                    }}
                                    components={{
                                        a: (
                                            // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                                            <a
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const href = e.currentTarget.getAttribute('href') || '';
                                                    trackTermsAndConditionsClick(href);
                                                    TrackEvent('terms_of_use_click', '/website-terms-of-use');
                                                    // Open the terms of use in a new page to maintain the flow of the user.
                                                    window.open(href, '_blank', 'noopener noreferrer');
                                                }}
                                                role="link" // Ensures it is treated as a link for screen readers
                                                tabIndex={0} // Makes it focusable with the keyboard
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        const href = e.currentTarget.getAttribute('href') || '';
                                                        trackTermsAndConditionsClick(href);
                                                        TrackEvent('terms_of_use_click', '/website-terms-of-use');
                                                        window.open(href, '_blank', 'noopener noreferrer');
                                                    }
                                                }}
                                            />
                                        )
                                    }}
                                />
                            </Text>

                            <Text color="slate">
                                <Trans i18nKey="membership.paymentMethod.terms.submitTerm2" />
                            </Text>

                            <Text color="slate">
                                <Trans
                                    i18nKey="membership.paymentMethod.terms.term1"
                                    values={{
                                        mailto: 'mailto:patientcare@birdirx.com',
                                        birdiPhone: getPhoneNumber({})
                                    }}
                                    components={{
                                        // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                                        a: <a />
                                    }}
                                />
                            </Text>

                            <Text color="slate">
                                <Trans i18nKey="membership.paymentMethod.terms.term2" />
                            </Text>

                            <Text color="slate">
                                <Trans i18nKey="membership.paymentMethod.terms.term3" />
                            </Text>

                            <Text color="slate">
                                <Trans
                                    i18nKey="membership.paymentMethod.terms.term4"
                                    values={{
                                        termsOfUseUrl: '/website-terms-of-use',
                                        privacyPolicyUrl: '/website-privacy-policy'
                                    }}
                                    components={{
                                        a: (
                                            // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                                            <a
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const href = e.currentTarget.getAttribute('href') || '';
                                                    trackTermsAndConditionsClick(href);
                                                    if (href === '/website-terms-of-use') {
                                                        TrackEvent(
                                                            'terms_of_use_click',
                                                            TRACKING_EVENTS.TERMS_OF_USE_CLICKED
                                                        );
                                                    } else {
                                                        TrackEvent(
                                                            'privacy_policy_click',
                                                            TRACKING_EVENTS.PRIVACY_POLICY_CLICKED
                                                        );
                                                    }
                                                    // Open the terms of use in a new page to maintain the flow of the user.
                                                    window.open(href, '_blank', 'noopener noreferrer');
                                                }}
                                                role="link" // Ensures it is treated as a link for screen readers
                                                tabIndex={0} // Makes it focusable with the keyboard
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        const href = e.currentTarget.getAttribute('href') || '';
                                                        trackTermsAndConditionsClick(href);
                                                        if (href === '/website-terms-of-use') {
                                                            TrackEvent(
                                                                'terms_of_use_click',
                                                                TRACKING_EVENTS.TERMS_OF_USE_CLICKED
                                                            );
                                                        } else {
                                                            TrackEvent(
                                                                'privacy_policy_click',
                                                                TRACKING_EVENTS.PRIVACY_POLICY_CLICKED
                                                            );
                                                        }
                                                        window.open(href, '_blank', 'noopener noreferrer');
                                                    }
                                                }}
                                            />
                                        )
                                    }}
                                />
                            </Text>
                        </div>
                    </form>
                </div>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default PaymentMethodPage;

export const query = graphql`
    query PaymentMethodPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        asideBottomImage: file(relativePath: { eq: "assets/images/setup-your-account-image.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG, layout: CONSTRAINED, width: 320)
            }
        }
        workflowLogo: file(relativePath: { eq: "assets/images/birdi-logo-care.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], layout: CONSTRAINED, quality: 100)
            }
        }
    }
`;
